var passwordGenerator = {
    init: function() {
        if ($('.page-password-generator').length) {
            passwordGenerator.generateOnLoad()
            passwordGenerator.generate();
        }
    },

    /**
     * Generuj hasło po kliknięciu w przycisk
     */
    generate: function() {
        $("#main .password-generator-container form").validate();
        $('#main .password-generator-container .btn-password-generate').on('click', function() {
            formData = $('#main .password-generator-container form').serialize();

            $.ajax({
                type: "POST",
                data: formData,
                url: "password-generator/generate",
                success: function(data) {
                    if (data != "") {
                        var jsonData = JSON.parse(data);
                        $('.password #password').val(jsonData);
                    }
                }
            });

            return false;
        });
    },

    /**
     * Generuj hasło przy wejściu na stronę.
     */
    generateOnLoad: function() {
        formData = $('#main .password-generator-container form').serialize();

        $.ajax({
            type: "POST",
            data: formData,
            url: "password-generator/generate",
            success: function(data) {
                if (data != "") {
                    var jsonData = JSON.parse(data);
                    $('.password #password').val(jsonData);
                }
            }
        });
    }
}


$(function() {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('.csrf').val()
        }
    });

    new ClipboardJS('.btn-copy');
    passwordGenerator.init();
});


