
require('./bootstrap');
require('jquery-validation');
require('./password-generator');

$(function() {
    /*alert('Jquery działa');*/
    //passwordGenerator.init();




    /**
     * Cookie
     */
    $('.cookies .close').on('click',function(){
        Cookies.set('cookie_accepted', '1', { expires: 7 });
        $('.cookies').slideUp(500);

        return false;
    });
});
